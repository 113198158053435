import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BACKEND_API } from "../../constants/backendApi";

//Data
import loginPopupData from "../../json/LoginScreen/button.json";

//Components
import NavbarComponent from "../../components/NavbarComponent/NavbarComponent";
import SideMenusComponent from "../../components/SideMenusComponent/SideMenusComponent";
import LoadingScreenComponent from "../../components/LoadingScreenComponent/LoadingScreenComponent";
import NavbarMobile from "../../components/NavbarMobile/NavbarMobile";
import NavbarMobileBottom from "../../components/NavbarMobileBotton/NavbarMobileBottom";
import VersionTxtComponent from "../../components/VersionTxtComponent/VersionTxtComponent";
import CreateCreditValidateDocumentBody from "../../components/CreateCreditValidateDocumentBody/CreateCreditValidateDocumentBody";
import PopUpComponent from "../../components/PopUpComponent/PopUpComponent";

//Context
import { LoadingContext } from "../../context/LoadingContext";
import { PopupContext } from "../../context/PopupContext";

const CreateCreditValidateDocumentScreen = ({ userData, userMenuData }) => {
  const history = useNavigate();

  const { loading } = useContext(LoadingContext);

  const { showPopup, popupData } = useContext(PopupContext);

  const token = localStorage.getItem("token");

  useEffect(() => {
    try {
      fetch(`${BACKEND_API}/api/survey`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message === "informacion ultima encuesta usuario") {
            let differenceInDays = 0;
            if (data.data.length > 0) {
              const dateCreated = new Date(data.data[0].dateCreated);
              const currentDate = new Date();
              const differenceInTime =
                currentDate.getTime() - dateCreated.getTime();
              differenceInDays = differenceInTime / (1000 * 3600 * 24);
            } else {
              differenceInDays = 20;
            }

            if (differenceInDays >= 15) {
              history("/admin/crear-credito/encuesta");
            } else {
              history("/admin/crear-credito/validar-documento");
            }
          }
        })
        .catch((err) => {
          history("/admin/crear-credito/validar-documento");
          console.log(err);
        });
    } catch (err) {
      history("/admin/crear-credito/validar-documento");
      console.log(err);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading && <LoadingScreenComponent />}
      {showPopup && (
        <PopUpComponent content={popupData} btnData={loginPopupData.data[0]} />
      )}
      <div className="body-container">
        <VersionTxtComponent />
        <div className="body-subcontainer">
          <NavbarComponent userData={userData} />
          <NavbarMobile userData={userData} menuData={userMenuData} />
          <CreateCreditValidateDocumentBody />
          <NavbarMobileBottom />
        </div>
        <SideMenusComponent menuData={userMenuData} />
      </div>
    </>
  );
};

export default CreateCreditValidateDocumentScreen;
