import React, { useState, useEffect, useContext } from "react";
import { utils, writeFile } from "xlsx";
import { BACKEND_API } from "../../constants/backendApi";

//Style
import "./CreditsInProcessBody.css";

//Components
import CreditDetailsTableComponent from "../CreditDetailsTableComponent/CreditDetailsTableComponent";

//Context
import { LoadingContext } from "../../context/LoadingContext";
import { PopupContext } from "../../context/PopupContext";

//hooks
import useDebounce from "../../hooks/useDebounce";

const CreditsInProcessBody = ({ getDataFromCreditTable, userData }) => {
  const { setLoading } = useContext(LoadingContext);

  const [maxData, setMaxData] = useState(10);

  const [initPage, setInitPage] = useState(0);

  const [creditList, setCreditList] = useState([]);

  const [creditListExcel, setCreditListExcel] = useState([]);

  const [totalCredits, setTotalCredits] = useState(0);

  const { setShowPopup, setPopupData } = useContext(PopupContext);

  const [filterData, setFilterData] = useState({
    type: "",
    document_plate: "",
    advisor: "",
    insurer: "",
    credit_state: "En proceso",
    next_to_renew: "",
  });

  const token = localStorage.getItem("token");

  const tableFields = [
    {
      name: "Nombre Cliente",
    },
    {
      name: "No. de Documento",
    },
    {
      name: "Aseguradora",
    },
    {
      name: "Vigencia Inicial",
    },
    {
      name: "Vigencia Final",
    },
    {
      name: "Dias de Vigencia",
    },
    {
      name: "No. de Poliza",
    },
    {
      name: "Placa/Detalle de Riesgo",
    },
    {
      name: "Referencia de Pago en Efecty",
    },
    {
      name: "Valor Cuota",
    },
    {
      name: "¿Ya Firmó?",
    },
    {
      name: "¿Tiene Cuota Inicial?",
    },
    {
      name: "Acciones",
    },
  ];

  useEffect(() => {
    const selectNextToRenew = document.getElementById("next_to_renew");

    const getMonthName = (month) => {
      const monthsArr = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      return monthsArr[month];
    };

    if (selectNextToRenew) {
      const currentMonth = new Date().getMonth();

      for (let i = 0; i < 3; i++) {
        const option = document.createElement("option");

        if (i === 0) {
          const monthName = getMonthName(currentMonth);
          option.text = monthName;
          option.value = `${i * 30}-${(i + 1) * 30}`;
        } else {
          const nextMonth = (currentMonth + i) % 12;
          const monthName = getMonthName(nextMonth);
          option.text = monthName;
          option.value = `${i * 30}-${(i + 1) * 30}`;
        }

        selectNextToRenew.appendChild(option);
      }
    }

    try {
      const selectInsurer = document.getElementById("insurer");

      fetch(`${BACKEND_API}/api/insurance-companies`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then(({ data }) => {
          data.map((insurer) => {
            const option = document.createElement("option");
            option.text = insurer.NombreAseguradora;
            option.value = insurer.IdAseguradora;
            selectInsurer.appendChild(option);

            const optionsArray = Array.from(selectInsurer.options);

            return optionsArray.sort((a, b) => a.text.localeCompare(b.text));
          });
        });
    } catch (err) {
      console.log(err);
    }

    const filterBody = {
      Filtro: filterData.document_plate,
      EstadoConsulta: "",
      IdAseguradora: filterData.insurer,
      Estado: "",
      AllData: true,
      Renovacion: false,
      Mora: false,
      EnProceso: true,
      NumeroRegistros: maxData,
      Inicial: initPage,
      Anio: "0",
      Mes: "0",
    };

    try {
      fetch(`${BACKEND_API}/api/credits`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(filterBody),
      })
        .then((res) => res.json())
        .then(({ data }) => {
          if (data.Creditos) {
            setCreditListExcel(data.Creditos);
          } else {
            setCreditList([]);
            setTotalCredits(0);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }, [
    filterData?.document_plate,
    filterData?.insurer,
    initPage,
    maxData,
    token,
    setLoading,
  ]);

  const handleResendDocusign = (creditId) => {
    try {
      fetch(`${BACKEND_API}/api/credit-docusing`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id: creditId }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message === "informacion credito docusign") {
            const resendDocusignTableArr = [
              [
                {
                  field_content: data.data.IdSobre,
                },
                {
                  field_content: data.data.EstadoDocuSign,
                },
              ],
            ];

            localStorage.setItem("docusignId", data.data.IdDocuSign);
            localStorage.setItem("docusignCreditId", creditId);

            getDataFromCreditTable(resendDocusignTableArr, true, {
              type: "resend_docusign",
            });
          } else if (!data.message?.DocuSign) {
            setShowPopup(true);
            setPopupData({
              title: "Enviado Exitosamente",
              info: "El crédito no cuenta con DocuSign.",
              type: "error",
            });
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  const sendCertificate = (creditData) => {
    const body = {
      data: [creditData],
    };

    if (body.data.length !== 0) {
      setLoading(true);
      try {
        fetch(`${BACKEND_API}/api/certificate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data && data.message === "Certificados") {
              setShowPopup(true);
              setPopupData({
                title: "Enviado Exitosamente",
                info: `Tu certificado ha sido enviado al siguiente correo electrónico: ${userData.email}`,
                type: "correct",
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const debouncedSearch = useDebounce((filterData) => {
    setLoading(true);

    const filterBody = {
      Filtro: filterData.document_plate,
      EstadoConsulta: "",
      IdAseguradora: filterData.insurer,
      Estado: "",
      AllData: false,
      Renovacion: false,
      Mora: false,
      EnProceso: true,
      NumeroRegistros: maxData,
      Inicial: initPage,
      Anio: "0",
      Mes: "0",
    };

    if (
      filterData.type === "FiltrosVarios" &&
      filterData.advisor === "" &&
      filterData.insurer === "" &&
      filterData.credit_state === ""
    ) {
      filterBody.TipoFiltro = "";
    }

    try {
      fetch(`${BACKEND_API}/api/credits`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(filterBody),
      })
        .then((res) => res.json())
        .then(({ data }) => {
          if (data.Creditos) {
            const creditsNewArr = data.Creditos.map((credit) => [
              {
                field_content: credit.NombreCliente,
              },
              {
                field_content: credit.Document,
              },
              {
                field_content: credit.Aseguradora,
              },
              {
                field_content: credit.Inicio_poliza,
              },
              {
                field_content: credit.Fin_poliza,
              },
              {
                field_content: credit.DiasDesdeVigencia,
              },
              {
                field_content: credit.NoPoliza,
              },
              {
                field_content: credit.Placa
                  ? credit.Placa
                  : credit.detalleRiesgo
                  ? credit.detalleRiesgo
                  : "N/A",
              },
              {
                field_content: credit.CuotasEnProceso
                  ? credit.CuotasEnProceso[0].Referenciadepago
                  : "N/A",
              },
              {
                field_content: credit.CuotasEnProceso
                  ? credit.CuotasEnProceso[0].ValorCuota.toLocaleString(
                      "es-CO",
                      {
                        style: "currency",
                        currency: "COP",
                      }
                    )
                  : "N/A",
              },
              {
                field_content: credit.PFirmado ? "Si" : "No",
              },
              {
                field_content: credit.CIPagada ? "Si" : "No",
              },
              {
                is_action_btn: true,
                btns: [
                  credit.PFirmado === false && {
                    function: () => handleResendDocusign(credit.IdCredit),
                    title: "Reenviar Firma Electrónica",
                    icon_src:
                      "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/resend-docusign-icon.webp",
                    icon_src_hover:
                      "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/resend-docusign-icon-hover.webp",
                  },
                  {
                    function: () =>
                      sendCertificate({
                        creditId: credit.credito,
                        clientId: credit.IdClient,
                        nameCertificate: "Recibo_de_pago",
                      }),
                    title: "Enviar Recibo de Pago",
                    icon_src:
                      "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/certification-module-icon-white.webp",
                    icon_src_hover:
                      "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/certification-module-icon.webp",
                  },
                ].filter(Boolean),
              },
            ]);

            setTotalCredits(data.TotalRegistros);
            setCreditList(creditsNewArr);
          } else {
            setCreditList([]);
            setTotalCredits(0);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }, 500);

  useEffect(() => {
    debouncedSearch(filterData);
    // eslint-disable-next-line
  }, [maxData, initPage, filterData]);

  const getMaxDataValue = (maxDataVal) => {
    setMaxData(maxDataVal);
  };

  const getPaginationData = (initPageVal) => {
    setInitPage(initPageVal);
  };

  const handleGetFilters = (e) => {
    if (e.target.name === "document_plate") {
      setFilterData({
        ...filterData,
        [e.target.name]: e.target.value,
      });
    }

    if (
      e.target.name === "advisor" ||
      e.target.name === "insurer" ||
      e.target.name === "credit_state" ||
      e.target.name === "next_to_renew"
    ) {
      setFilterData({
        ...filterData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleCleanFilters = () => {
    setFilterData({
      type: "",
      document_plate: "",
      advisor: "",
      insurer: "",
      credit_state: "",
      next_to_renew: "",
    });
  };

  const exportToExcel = () => {
    const jsonData = creditListExcel.map((credit) => {
      return {
        NombreCliente: credit.NombreCliente,
        Aseguradora: credit.Aseguradora,
        TieneFirma: credit.PFirmado ? "Si" : "No",
        TieneCI: credit.CIPagada ? "Si" : "No",
        VigenciaInicial: credit.Inicio_poliza,
        VigenciaFinal: credit.Fin_poliza,
        DiasVigencia: credit.DiasDesdeVigencia,
        NoDocumento: credit.Document,
        PlacaDetalleRiesgo: !credit.Placa ? credit.detalleRiesgo : credit.Placa,
        NoPoliza: credit.NoPoliza,
      };
    });

    const workbook = utils.book_new();

    const worksheet = utils.json_to_sheet(jsonData);

    utils.book_append_sheet(workbook, worksheet, "Creditos en proceso");

    writeFile(workbook, "Creditos en proceso.xlsx");
  };

  return (
    <section className="credits-in-process-table-section">
      <div className="credits-in-process-table-subcontainer">
        <div className="cip-title-container">
          <h1 className="cip-title">Créditos en Proceso</h1>
        </div>
        <div className="cip-description-txt-container">
          <p className="cipdt-txt">
            Los créditos en proceso son los que les hace falta algún requisito
            (firma de documentos y/o pago de la cuota inicial) para que pase a
            desembolsado. Los créditos que están pendientes por desembolsar a la
            aseguradora no aparecen en este listado.
          </p>
        </div>
        <div className="filter-elms-container">
          <div className="input-container">
            <form onChange={handleGetFilters} className="filter-form-container">
              <label>
                <h1 className="label-title">Número de Documento o Placa:</h1>
                <input
                  className="filter-input-search"
                  type="text"
                  placeholder="Numero de Documento o Placa"
                  title="Numero de Documento o Placa"
                  name="document_plate"
                  value={filterData.document_plate}
                />
              </label>
              <label>
                <h1 className="label-title">Aseguradora:</h1>
                <select
                  name="insurer"
                  id="insurer"
                  disabled={filterData.document_plate !== "" ? true : false}
                  value={filterData.insurer}
                >
                  <option value="">--Seleccione--</option>
                </select>
              </label>
            </form>
            <button
              className="c-btn-search"
              title="Limpiar Filtros"
              onClick={handleCleanFilters}
            >
              Limpiar Filtros
            </button>
          </div>
        </div>
        <div
          className="cip-download-excel-container"
          style={{ display: creditList.length > 0 ? "unset" : "none" }}
        >
          <button
            title="Descargar Excel"
            className="cip-de-btn"
            onClick={() => exportToExcel()}
          >
            Descargar Excel
          </button>
        </div>
        <div className="credit-details-table-supercontainer">
          <CreditDetailsTableComponent
            getMaxData={getMaxDataValue}
            maxData={maxData}
            getPaginationInit={getPaginationData}
            credits={creditList}
            totalCreditsNum={totalCredits}
            filterData={filterData}
            fields={tableFields}
          />
        </div>
      </div>
    </section>
  );
};

export default CreditsInProcessBody;
