import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { BACKEND_API } from "../../constants/backendApi";

//Style
import "./FormWithStepsComponent.css";

//Context
import { PopupContext } from "../../context/PopupContext";
import { LoadingContext } from "../../context/LoadingContext";

const FormWithStepsComponent = ({
  createCreditUserData,
  inputData,
  getRefreshDataState,
  refreshData,
  hasSteps,
  hasRestoreBtn,
}) => {
  const history = useNavigate();

  const { setLoading } = useContext(LoadingContext);

  const location = useLocation();

  const [stepNumber, setStepNumber] = useState(0);

  const [progress, setProgress] = useState(1);

  const progressFraction = 100 / inputData.steps.length;

  const [formData, setFormData] = useState({});

  const { setShowPopup, setPopupData } = useContext(PopupContext);

  const token = localStorage.getItem("token");

  const [modifiedField, setModifiedField] = useState([]);

  const userProfile = localStorage.getItem("userProfile") || "User";

  useEffect(() => {
    if (location.pathname === "/admin/crear-credito/formulario-confirmar") {
      const capitalizeFirstLetters = (str) => {
        return str.replace(/\b\w/g, (match) => match.toUpperCase());
      };

      const dataFromLS = JSON.parse(localStorage.getItem("data"));

      setFormData({
        req_type: inputData.type,
        first_name:
          createCreditUserData && createCreditUserData.Nombres
            ? capitalizeFirstLetters(createCreditUserData.Nombres.toLowerCase())
            : null,
        last_name:
          createCreditUserData && createCreditUserData.Apellidos
            ? capitalizeFirstLetters(
                createCreditUserData.Apellidos.toLowerCase()
              )
            : "N/A",
        doc_type:
          createCreditUserData && createCreditUserData.tipo_documento
            ? createCreditUserData.tipo_documento
            : null,
        num_document:
          createCreditUserData && createCreditUserData.NumeroDoc
            ? createCreditUserData.NumeroDoc
            : null,
        birth_department: "",
        birth_place:
          createCreditUserData && createCreditUserData.CiudadDeNacimiento
            ? createCreditUserData.CiudadDeNacimiento
            : null,
        birthdate:
          createCreditUserData && createCreditUserData.FechaNacimiento
            ? createCreditUserData.FechaNacimiento
            : null,
        genre:
          createCreditUserData && createCreditUserData.Sexo
            ? createCreditUserData.Sexo
            : null,
        email:
          dataFromLS && dataFromLS.correo_electronico
            ? dataFromLS.correo_electronico
            : null,
        phone:
          dataFromLS && dataFromLS.cel_titular_credito
            ? dataFromLS.cel_titular_credito
            : null,
        agent: null,
        job:
          dataFromLS && dataFromLS.ocupacion_titular_credito
            ? dataFromLS.ocupacion_titular_credito
            : null,
        salary:
          dataFromLS && dataFromLS.ingresos_titular_del_credito
            ? dataFromLS.ingresos_titular_del_credito
            : null,
        country: "",
        department: "",
        city:
          dataFromLS && dataFromLS.ciudad_residencia
            ? dataFromLS.ciudad_residencia
            : null,
        address:
          dataFromLS && dataFromLS.direccion_residencia
            ? dataFromLS.direccion_residencia
            : null,
        num_policy:
          createCreditUserData && createCreditUserData.No_Poliza
            ? createCreditUserData.No_Poliza
            : "",
        insurer_place:
          createCreditUserData && createCreditUserData.Idsucursal
            ? createCreditUserData.Idsucural
            : "",
        insurer:
          createCreditUserData && createCreditUserData.Aseguradora
            ? createCreditUserData.Aseguradora
            : "",
        name_broker: "",
        broker:
          dataFromLS && dataFromLS.asesor_intermediario
            ? dataFromLS.asesor_intermediario
            : "",
        plate:
          createCreditUserData && createCreditUserData.Placa
            ? createCreditUserData.Placa
            : "",
        init_term:
          createCreditUserData && createCreditUserData.Vig_inicial
            ? createCreditUserData.Vig_inicial
            : "",
        total_annual:
          createCreditUserData && createCreditUserData.Prima_Total
            ? createCreditUserData.Prima_Total
            : "",
        init_credit:
          createCreditUserData && createCreditUserData.Abono_Inicial
            ? createCreditUserData.Abono_Inicial
            : null,
        num_shares: "",
        credit_type: "",
        line:
          createCreditUserData && createCreditUserData.Linea
            ? createCreditUserData.Linea
            : null,
        brokerage_income:
          createCreditUserData && createCreditUserData.ing_Intermediario
            ? createCreditUserData.ing_Intermediario
            : null,
      });
    }

    if (
      location.pathname ===
      "/admin/crear-credito/formulario-renovacion-confirmar"
    ) {
      const capitalizeFirstLetters = (str) => {
        return str.replace(/\b\w/g, (match) => match.toUpperCase());
      };

      try {
        const doc = JSON.parse(localStorage.getItem("user_document"));

        fetch(`${BACKEND_API}/api/document-client?document=${doc}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data && data.message === "Documents") {
              setFormData({
                ...formData,
                email: data.data.Email,
                phone: data.data.Phone,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }

      const dataFromLS = JSON.parse(localStorage.getItem("data"));

      setFormData({
        req_type: inputData.type,
        first_name:
          createCreditUserData && createCreditUserData.Nombres
            ? capitalizeFirstLetters(createCreditUserData.Nombres.toLowerCase())
            : null,
        last_name:
          createCreditUserData && createCreditUserData.Apellidos
            ? capitalizeFirstLetters(
                createCreditUserData.Apellidos.toLowerCase()
              )
            : "N/A",
        doc_type:
          createCreditUserData && createCreditUserData.tipo_documento
            ? createCreditUserData.tipo_documento
            : null,
        num_document:
          dataFromLS && dataFromLS.numero_doc ? dataFromLS.numero_doc : null,
        birth_department: "",
        birth_place:
          createCreditUserData && createCreditUserData.CiudadDeNacimiento
            ? createCreditUserData.CiudadDeNacimiento
            : null,
        birthdate:
          createCreditUserData && createCreditUserData.FechaNacimiento
            ? createCreditUserData.FechaNacimiento
            : null,
        genre:
          createCreditUserData && createCreditUserData.Sexo
            ? createCreditUserData.Sexo
            : null,
        email:
          dataFromLS && dataFromLS.correo_electronico
            ? dataFromLS.correo_electronico
            : null,
        phone:
          dataFromLS && dataFromLS.cel_titular_credito
            ? dataFromLS.cel_titular_credito
            : null,
        job:
          dataFromLS && dataFromLS.ocupacion_titular_credito
            ? dataFromLS.ocupacion_titular_credito
            : null,
        salary:
          dataFromLS && dataFromLS.ingresos_titular_del_credito
            ? dataFromLS.ingresos_titular_del_credito
            : null,
        country: "",
        department: "",
        city:
          dataFromLS && dataFromLS.ciudad_residencia
            ? dataFromLS.ciudad_residencia
            : null,
        address:
          dataFromLS && dataFromLS.direccion_residencia
            ? dataFromLS.direccion_residencia
            : null,
        num_policy:
          createCreditUserData && createCreditUserData.No_Poliza
            ? createCreditUserData.No_Poliza
            : "",
        insurer_place:
          createCreditUserData && createCreditUserData.Idsucursal
            ? createCreditUserData.Idsucural
            : "",
        insurer:
          createCreditUserData && createCreditUserData.Aseguradora
            ? createCreditUserData.Aseguradora
            : "",
        name_broker: "",
        broker:
          dataFromLS && dataFromLS.asesor_intermediario
            ? dataFromLS.asesor_intermediario
            : "",
        plate:
          createCreditUserData && createCreditUserData.Placa
            ? createCreditUserData.Placa
            : "",
        init_term:
          createCreditUserData && createCreditUserData.Vig_inicial
            ? createCreditUserData.Vig_inicial
            : "",
        total_annual:
          createCreditUserData && createCreditUserData.Prima_Total
            ? createCreditUserData.Prima_Total
            : "",
        init_credit:
          createCreditUserData && createCreditUserData.Abono_Inicial
            ? createCreditUserData.Abono_Inicial
            : null,
        num_shares: "",
        credit_type: "",
        line:
          createCreditUserData && createCreditUserData.Linea
            ? createCreditUserData.Linea
            : null,
        agent: null,
        brokerage_income:
          createCreditUserData && createCreditUserData.ing_Intermediario
            ? createCreditUserData.ing_Intermediario
            : null,
      });
    }
    // eslint-disable-next-line
  }, [createCreditUserData]);

  useEffect(() => {
    if (location.pathname === "/admin/crear-credito/formulario-confirmar") {
      switch (inputData.type) {
        case "Nuevo":
          setFormData({
            req_type: inputData.type,
            first_name: "",
            last_name: "",
            doc_type: "",
            num_document: "",
            birthdate: "",
            genre: "",
            email: "",
            phone: "",
            agent: "",
            job: "",
            salary: "",
            country: "",
            department: "",
            city: "",
            address: "",
            num_policy: "",
            insurer_place: "",
            insurer: "",
            name_broker: "",
            nit_broker: "",
            broker: "",
            plate: "",
            init_term: "",
            total_annual: "",
            init_credit: "",
            num_shares: "",
            credit_type: "",
            brokerage_income: "",
          });
          break;
        case "Renovación":
          setFormData({
            req_type: inputData.type,
            first_name: "",
            last_name: "",
            doc_type: "",
            agent: "",
            num_document: "",
            num_policy: "",
            insurer: "",
            name_broker: "",
            nit_broker: "",
            broker: "",
            plate: "",
            init_term: "",
            total_annual: "",
            init_credit: "",
            num_shares: "",
            credit_type: "",
            brokerage_income: "",
          });
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const selectDept = document.getElementById("birth_department");

    if (selectDept) {
      try {
        fetch(
          `${BACKEND_API}/api/departament-city?id=${formData.birth_place}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then(({ data }) => {
            if (data.Departamento) {
              setFormData({
                ...formData,
                birth_department: data.Departamento[0].Id,
              });

              const index = Array.from(selectDept.options).findIndex(
                (option) => option.value === data.Departamento[0].Id
              );

              selectDept.selectedIndex = index;
            }
          });
      } catch (err) {
        console.log(err);
      }
    }
    // eslint-disable-next-line
  }, [formData?.birth_place]);

  useEffect(() => {
    const selectCity = document.getElementById("birth_place");

    if (selectCity) {
      try {
        selectCity.innerHTML = "";

        const body = {
          id_department: formData.birth_department,
          get_cities: true,
          get_departments: false,
        };
        fetch("https://crediseguro-back.click/getLocation", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.Ciudad) {
              const noneOption = document.createElement("option");
              noneOption.text = "--Ninguno--";
              noneOption.value = "";

              selectCity.appendChild(noneOption);

              data.Ciudad.map((city) => {
                const option = document.createElement("option");
                option.text = city.Nombre;
                option.value = city.Id;
                selectCity.appendChild(option);

                const optionsArray = Array.from(selectCity.options);

                optionsArray.sort((a, b) => a.text.localeCompare(b.text));

                selectCity.innerHTML = "";

                return optionsArray.forEach((option) => {
                  selectCity.appendChild(option);
                });
              });

              const index = Array.from(selectCity.options).findIndex(
                (option) => option.value === formData.birth_place
              );

              selectCity.selectedIndex = index;
            }
          });
      } catch (err) {
        console.log(err);
      }
    }
    // eslint-disable-next-line
  }, [formData.birth_department]);

  useEffect(() => {
    switch (location.pathname) {
      case "/admin/crear-credito/verificar-datos":
        const userData = JSON.parse(localStorage.getItem("userData"));

        setFormData({
          ...formData,
          phone: userData.phone,
          email: userData.email,
        });

        break;
      case "/admin/crear-credito/formulario-renovacion-confirmar":
        if (stepNumber === 0) {
          const selectInsurer = document.getElementById("insurer");

          const insuranceIdLS = localStorage.getItem("insurance_id");

          const isFromRenovationTable =
            localStorage.getItem("fromRenovationTable") || false;

          try {
            fetch(`${BACKEND_API}/api/insurance-companies`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json",
              },
            })
              .then((res) => res.json())
              .then(({ data }) => {
                const selectedInsurer = data.filter(
                  (item) => item.IdAseguradora === insuranceIdLS
                );

                selectedInsurer.map((insurer) => {
                  const option = document.createElement("option");
                  option.text = insurer.NombreAseguradora;
                  option.value = insurer.IdAseguradora;
                  selectInsurer.appendChild(option);

                  const optionsArray = Array.from(selectInsurer.options);

                  optionsArray.sort((a, b) => a.text.localeCompare(b.text));

                  selectInsurer.innerHTML = "";

                  return optionsArray.forEach((option) => {
                    selectInsurer.appendChild(option);
                  });
                });
                try {
                  const selectInsurerPlace =
                    document.getElementById("insurer_place");

                  fetch(`${BACKEND_API}/api/subsidiary?id=${insuranceIdLS}`, {
                    method: "GET",
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-type": "application/json",
                    },
                  })
                    .then((res) => res.json())
                    .then(({ data }) => {
                      if (data.EstadoConsulta === "Exitoso") {
                        data.Sucursal.map((insurerPlace) => {
                          const option = document.createElement("option");
                          option.text = insurerPlace.Nombre;
                          option.value = insurerPlace.Id;
                          selectInsurerPlace.appendChild(option);

                          const optionsArray = Array.from(
                            selectInsurerPlace.options
                          );

                          optionsArray.sort((a, b) =>
                            a.text.localeCompare(b.text)
                          );

                          selectInsurerPlace.innerHTML = "";

                          return optionsArray.forEach((option) => {
                            selectInsurerPlace.appendChild(option);
                          });
                        });
                      }
                    });
                } catch (err) {
                  console.log(err);
                }
              });
          } catch (err) {
            console.log(err);
          }

          if (isFromRenovationTable) {
            try {
              const selectBroker = document.getElementById("broker");

              fetch(`${BACKEND_API}/api/advisors`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-type": "application/json",
                },
              })
                .then((res) => res.json())
                .then(({ data }) => {
                  data.map((broker) => {
                    const option = document.createElement("option");
                    option.text = broker.NombreAsesor;
                    option.value = broker.IdAsesor;
                    selectBroker.appendChild(option);

                    const optionsArray = Array.from(selectBroker.options);

                    return optionsArray.sort((a, b) =>
                      a.text.localeCompare(b.text)
                    );
                  });
                });
            } catch (err) {
              console.log(err);
            }
          }

          const isAdminPreview = localStorage.getItem("adminPreview");
          const selectAgent = document.getElementById("agent");

          if (isAdminPreview && selectAgent) {
            try {
              fetch(`${BACKEND_API}/api/agent`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              })
                .then((res) => res.json())
                .then((data) => {
                  if (data.message === "informacion agentes") {
                    // Elimina todas las opciones anteriores, excepto la predeterminada
                    selectAgent.innerHTML =
                      '<option value="">--Ninguno--</option>';

                    const agentsOptions = data.data.map((agent) => {
                      const option = document.createElement("option");
                      option.text = agent.name;
                      option.value = `${agent.document}-${agent.name}`;
                      return option;
                    });

                    // Ordenar las opciones alfabéticamente por texto
                    agentsOptions.sort((a, b) => a.text.localeCompare(b.text));

                    // Añadir las opciones ordenadas al select
                    agentsOptions.forEach((option) => {
                      selectAgent.appendChild(option);
                    });
                  }
                });
            } catch (err) {
              console.log(err);
            }
          }

          const selectLine = document.getElementById("line");

          try {
            fetch(`${BACKEND_API}/api/line`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json",
              },
            })
              .then((res) => res.json())
              .then(({ data }) => {
                if (data.length > 0) {
                  data.map((line) => {
                    const option = document.createElement("option");
                    option.text = line;
                    option.value = line;
                    selectLine.appendChild(option);

                    const optionsArray = Array.from(selectLine.options);

                    optionsArray.sort((a, b) => a.text.localeCompare(b.text));

                    selectLine.innerHTML = "";

                    optionsArray.forEach((option) => {
                      selectLine.appendChild(option);
                    });

                    return null;
                  });
                }
              });
          } catch (err) {
            console.log(err);
          }
        }
        break;
      case "/admin/crear-credito/formulario-confirmar":
        if (stepNumber === 0 && inputData.type === "Nuevo") {
          const selectDept = document.getElementById("birth_department");

          const body = {
            id_department: "",
            get_cities: false,
            get_departments: true,
          };
          fetch("https://crediseguro-back.click/getLocation", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          })
            .then((res) => res.json())
            .then((data) => {
              data.Departamento.map((dept) => {
                const option = document.createElement("option");
                option.text = dept.Nombre;
                option.value = dept.Id;
                selectDept.appendChild(option);

                const optionsArray = Array.from(selectDept.options);

                optionsArray.sort((a, b) => a.text.localeCompare(b.text));

                selectDept.innerHTML = "";

                return optionsArray.forEach((option) => {
                  selectDept.appendChild(option);
                });
              });
            });

          const isAdminPreview = localStorage.getItem("adminPreview");
          const selectAgent = document.getElementById("agent");

          if (isAdminPreview && selectAgent) {
            try {
              fetch(`${BACKEND_API}/api/agent`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              })
                .then((res) => res.json())
                .then((data) => {
                  if (data.message === "informacion agentes") {
                    // Elimina todas las opciones anteriores, excepto la predeterminada
                    selectAgent.innerHTML =
                      '<option value="">--Ninguno--</option>';

                    const agentsOptions = data.data.map((agent) => {
                      const option = document.createElement("option");
                      option.text = agent.name;
                      option.value = `${agent.document}-${agent.name}`;
                      return option;
                    });

                    // Ordenar las opciones alfabéticamente por texto
                    agentsOptions.sort((a, b) => a.text.localeCompare(b.text));

                    // Añadir las opciones ordenadas al select
                    agentsOptions.forEach((option) => {
                      selectAgent.appendChild(option);
                    });
                  }
                });
            } catch (err) {
              console.log(err);
            }
          }
        }

        if (stepNumber === 1) {
          const selectInsurer = document.getElementById("insurer");

          const insuranceIdLS = localStorage.getItem("insurance_id");

          try {
            fetch(`${BACKEND_API}/api/insurance-companies`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json",
              },
            })
              .then((res) => res.json())
              .then(({ data }) => {
                const selectedInsurer = data.filter(
                  (item) => item.IdAseguradora === insuranceIdLS
                );

                selectedInsurer.map((insurer) => {
                  const option = document.createElement("option");
                  option.text = insurer.NombreAseguradora;
                  option.value = insurer.IdAseguradora;
                  selectInsurer.appendChild(option);

                  const optionsArray = Array.from(selectInsurer.options);

                  optionsArray.sort((a, b) => a.text.localeCompare(b.text));

                  selectInsurer.innerHTML = "";

                  return optionsArray.forEach((option) => {
                    selectInsurer.appendChild(option);
                  });
                });
                try {
                  const selectInsurerPlace =
                    document.getElementById("insurer_place");

                  fetch(`${BACKEND_API}/api/subsidiary?id=${insuranceIdLS}`, {
                    method: "GET",
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-type": "application/json",
                    },
                  })
                    .then((res) => res.json())
                    .then(({ data }) => {
                      if (data.EstadoConsulta === "Exitoso") {
                        data.Sucursal.map((insurerPlace) => {
                          const option = document.createElement("option");
                          option.text = insurerPlace.Nombre;
                          option.value = insurerPlace.Id;
                          selectInsurerPlace.appendChild(option);

                          const optionsArray = Array.from(
                            selectInsurerPlace.options
                          );

                          optionsArray.sort((a, b) =>
                            a.text.localeCompare(b.text)
                          );

                          selectInsurerPlace.innerHTML = "";

                          optionsArray.forEach((option) => {
                            selectInsurerPlace.appendChild(option);
                          });

                          if (
                            createCreditUserData.Idsucursal &&
                            createCreditUserData.Idsucursal === insurerPlace.Id
                          ) {
                            selectInsurerPlace.value =
                              createCreditUserData.Idsucursal;
                          }

                          return null;
                        });
                      }
                    });
                } catch (err) {
                  console.log(err);
                }
              });
          } catch (err) {
            console.log(err);
          }

          const selectLine = document.getElementById("line");

          try {
            fetch(`${BACKEND_API}/api/line`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json",
              },
            })
              .then((res) => res.json())
              .then(({ data }) => {
                if (data.length > 0) {
                  data.map((line) => {
                    const option = document.createElement("option");
                    option.text = line;
                    option.value = line;
                    selectLine.appendChild(option);

                    const optionsArray = Array.from(selectLine.options);

                    optionsArray.sort((a, b) => a.text.localeCompare(b.text));

                    selectLine.innerHTML = "";

                    optionsArray.forEach((option) => {
                      selectLine.appendChild(option);
                    });

                    return null;
                  });
                }
              });
          } catch (err) {
            console.log(err);
          }
        }

        break;
      case "/admin/crear-credito/formulario-renovacion":
      case "/admin/crear-credito/formulario":
        if (stepNumber === 0 && inputData.type === "Nuevo") {
          const selectDept = document.getElementById("department");

          const body = {
            id_department: "",
            get_cities: false,
            get_departments: true,
          };
          fetch("https://crediseguro-back.click/getLocation", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          })
            .then((res) => res.json())
            .then((data) => {
              data.Departamento.map((dept) => {
                const option = document.createElement("option");
                option.text = dept.Nombre;
                option.value = dept.Id;
                selectDept.appendChild(option);

                const optionsArray = Array.from(selectDept.options);

                optionsArray.sort((a, b) => a.text.localeCompare(b.text));

                selectDept.innerHTML = "";

                return optionsArray.forEach((option) => {
                  selectDept.appendChild(option);
                });
              });
            });
          try {
          } catch (err) {
            console.log(err);
          }
        }
        break;
      case "/admin/crear-credito/validar-documento":
        try {
          const selectBroker = document.getElementById("broker");

          fetch(`${BACKEND_API}/api/advisors`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json",
            },
          })
            .then((res) => res.json())
            .then(({ data }) => {
              data.map((broker) => {
                const option = document.createElement("option");
                option.text = broker.NombreAsesor;
                option.value = broker.IdAsesor;
                selectBroker.appendChild(option);

                const optionsArray = Array.from(selectBroker.options);

                return optionsArray.sort((a, b) =>
                  a.text.localeCompare(b.text)
                );
              });
            });
        } catch (err) {
          console.log(err);
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [stepNumber]);

  const handleForm = (e) => {
    setModifiedField([...modifiedField, e.target.name]);

    if (e.target.title === "text") {
      if (e.target.name === "plate") {
        e.target.value = e.target.value
          .replace(/\s/g, "")
          .toUpperCase()
          .substring(0, 6);
      } else {
        const inputValue = e.target.value.trimStart();
        const lowercaseValue = inputValue.toLowerCase();

        e.target.value = lowercaseValue
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      }

      if (e.target.value.trim() === "") {
        e.target.nextElementSibling.classList.remove("hide");
      } else {
        e.target.nextElementSibling.classList.add("hide");
      }
    }

    if (e.target.title === "select") {
      if (e.target.value.trim() === "") {
        e.target.nextElementSibling.classList.remove("hide");
      } else {
        e.target.nextElementSibling.classList.add("hide");
      }
    }

    if (e.target.title === "number") {
      e.target.value = e.target.value.replace(/[^0-9]/g, "");

      if (e.target.name === "init_credit") {
        e.target.value = parseInt(e.target.value, 10);

        if (isNaN(e.target.value) || e.target.value > 10000000) {
          e.target.value = isNaN(e.target.value) ? 0 : 10000000;
        }
      }

      if (e.target.value.trim() === "") {
        e.target.nextElementSibling.classList.remove("hide");
      } else {
        e.target.nextElementSibling.classList.add("hide");
      }
    }

    if (e.target.title === "date") {
      const birthdate = new Date(e.target.value);
      const today = new Date();
      const age = today.getFullYear() - birthdate.getFullYear();

      if (
        (e.target.name === "birthdate" && age < 18) ||
        birthdate.getFullYear() < 1900
      ) {
        e.target.nextElementSibling.innerText = "Debes ser mayor de edad";
        e.target.nextElementSibling.classList.remove("hide");
      } else {
        e.target.nextElementSibling.classList.add("hide");
      }
    }

    if (e.target.name === "init_term") {
      const currentDate = new Date(e.target.value);
      const limitDate = new Date();
      const limitDateMin = new Date();

      limitDateMin.setMonth(limitDateMin.getMonth() - 2);

      limitDate.setDate(limitDate.getDate() + 45);

      if (currentDate > limitDate || currentDate < limitDateMin) {
        e.target.nextElementSibling.innerText =
          "La vigencia inicial no debe ser mayor a 45 dias, ni anterior a 2 meses";
        e.target.nextElementSibling.classList.remove("hide");
      } else {
        e.target.nextElementSibling.classList.add("hide");
      }
    }

    if (e.target.title === "email") {
      const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!regexEmail.test(e.target.value)) {
        e.target.nextElementSibling.innerText = "Ingresa un correo valido";
        e.target.nextElementSibling.classList.remove("hide");
      } else {
        e.target.nextElementSibling.classList.add("hide");
      }
    }

    if (e.target.title === "phone") {
      e.target.value = e.target.value.replace(/\s/g, "").replace(/\D/g, "");

      if (e.target.value.length > 10) {
        e.target.value = e.target.value.substring(0, 10);
      }

      if (
        e.target.value[0] !== "3" ||
        e.target.value[1] === "3" ||
        e.target.value.trim() === "" ||
        e.target.value.length < 10
      ) {
        e.target.nextElementSibling.innerText = "Ingresa un número valido";
        e.target.nextElementSibling.classList.remove("hide");
      } else {
        if (
          e.target.name === "00N5G00000WiqJe" &&
          e.target.value === formData["00N5G00000WiqHn"]
        ) {
          e.target.nextElementSibling.innerText =
            "El número no puede ser igual";
          e.target.nextElementSibling.classList.remove("hide");
        } else {
          e.target.nextElementSibling.classList.add("hide");
        }
      }
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formId = localStorage.getItem("form_id");

    const token = localStorage.getItem("token");

    switch (location.pathname) {
      case "/admin/crear-credito/verificar-datos":
        const verifyUserData = JSON.parse(localStorage.getItem("userData"));

        try {
          setLoading(true);

          fetch(
            `${BACKEND_API}/api/document-client?document=${verifyUserData.num_doc}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.message === "Documents") {
                const userData = {
                  phone:
                    verifyUserData.phone !== formData.phone
                      ? formData.phone
                      : data.data.Phone,
                  email:
                    verifyUserData.email !== formData.email
                      ? formData.email
                      : data.data.Email,
                  num_doc: verifyUserData.num_doc,
                };

                localStorage.setItem("userData", JSON.stringify(userData));
                localStorage.setItem("latestStep", "verify-data");
                history("/admin/crear-credito/formulario-renovacion");
              }
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (err) {
          console.log(err);
        }

        break;
      case "/admin/crear-credito/validar-documento":
        setLoading(true);

        localStorage.setItem("latestStep", "validate-document");

        const uuid = uuidv4();
        const valuesArr = uuid.split("-");

        const id = valuesArr[0] + valuesArr[1];

        localStorage.setItem("broker_id", formData.broker);
        localStorage.setItem("form_id", id);

        try {
          const body = {
            vig_inicial: null,
            sexo: null,
            prima_total: null,
            placa: null,
            ocupacion_titular_credito: null,
            numero_doc: null,
            nombres: null,
            no_poliza: null,
            no_cuotas: null,
            linea: null,
            ingresos_titular_del_credito: null,
            id: id,
            fecha_nacimiento: null,
            fecha_expedicion: null,
            estado_civil: null,
            departamento_nacimiento: null,
            departamento_expedicion: null,
            correo_electronico: null,
            ciudad_nacimiento: null,
            ciudad_expedicion: null,
            cel_titular_credito: null,
            asesor_intermediario: formData.broker,
            apellidos: null,
            abono_inicial: null,
            ciudad_residencia: null,
            direccion_residencia: null,
          };

          localStorage.setItem("data", JSON.stringify(body));
        } catch (err) {
          console.log(err);
        }

        try {
          fetch(
            `https://crediseguro-back.click/getDocument/${formData.num_document}`,
            {
              method: "GET",
            }
          )
            .then((res) => res.json())
            .then((data) => {
              if (
                data &&
                data.message === "Información obtendida exitosamente"
              ) {
                const userData = {
                  phone: data.data.Phone,
                  email: data.data.Email,
                  num_doc: data.data.NoDocumento,
                };

                if (formData.credit_type === "renovation") {
                  localStorage.setItem("userData", JSON.stringify(userData));
                  localStorage.setItem("processType", "renovation");
                  localStorage.setItem("isCreditInProcess", true);
                  history("/admin/crear-credito/adjuntar-poliza");
                } else {
                  localStorage.setItem("userData", JSON.stringify(userData));
                  localStorage.setItem("processType", "new-credit");
                  localStorage.setItem("isCreditInProcess", true);
                  history("/admin/crear-credito/adjuntar-poliza");
                }
                setLoading(false);
              } else {
                try {
                  const body = {
                    vig_inicial: null,
                    sexo: null,
                    prima_total: null,
                    placa: null,
                    ocupacion_titular_credito: null,
                    numero_doc: null,
                    nombres: null,
                    no_poliza: null,
                    no_cuotas: null,
                    linea: null,
                    ingresos_titular_del_credito: null,
                    id: id,
                    fecha_nacimiento: null,
                    fecha_expedicion: null,
                    estado_civil: null,
                    departamento_nacimiento: null,
                    departamento_expedicion: null,
                    correo_electronico: null,
                    ciudad_nacimiento: null,
                    ciudad_expedicion: null,
                    cel_titular_credito: null,
                    asesor_intermediario: formData.broker,
                    apellidos: "N/A",
                    abono_inicial: null,
                    id_sucursal: null,
                    ciudad_residencia: null,
                    direccion_residencia: null,
                    es_renovacion: false,
                  };
                  fetch(`${BACKEND_API}${inputData.endpoint}`, {
                    method: "PUT",
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-type": "application/json",
                    },
                    body: JSON.stringify(body),
                  })
                    .then((res) => res.json())
                    .then(({ data }) => {
                      if (data.Estado === "Exitoso") {
                        localStorage.setItem("userData", JSON.stringify({}));
                        localStorage.setItem("processType", "new-credit");
                        localStorage.setItem("isCreditInProcess", true);
                        history("/admin/crear-credito/adjuntar-documento");
                        setLoading(false);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } catch (err) {
                  console.log(err);
                }
              }
            })
            .catch(() => {
              localStorage.setItem("userData", JSON.stringify({}));
              localStorage.setItem("isCreditInProcess", true);
              localStorage.setItem("processType", "new-credit");
              history("/admin/crear-credito/adjuntar-documento");
              setLoading(false);
            });
        } catch (err) {
          console.log(err);
        }
        break;
      case "/admin/crear-credito/formulario-renovacion":
        setLoading(true);
        localStorage.setItem("latestStep", "complement-data-renovation");

        const userDataFromLS = JSON.parse(localStorage.getItem("userData"));

        const brokerRenovationId = localStorage.getItem("broker_id");

        try {
          const body = {
            vig_inicial: null,
            sexo: null,
            prima_total: null,
            placa: null,
            ocupacion_titular_credito: null,
            numero_doc: userDataFromLS.num_doc,
            nombres: null,
            no_poliza: null,
            no_cuotas: 1,
            linea: null,
            ingresos_titular_del_credito: null,
            id: formId,
            fecha_nacimiento: null,
            fecha_expedicion: null,
            estado_civil: null,
            departamento_nacimiento: null,
            departamento_expedicion: null,
            correo_electronico: userDataFromLS.email,
            ciudad_nacimiento: null,
            ciudad_expedicion: null,
            cel_titular_credito: userDataFromLS.phone,
            asesor_intermediario: brokerRenovationId,
            apellidos: null,
            abono_inicial: null,
            ciudad_residencia: formData.city,
            direccion_residencia: formData.address,
          };

          localStorage.setItem("data", JSON.stringify(body));
          setTimeout(() => {
            history(inputData.redirect);
          }, 5000);
        } catch (err) {
          console.log(err);
        }
        break;
      case "/admin/crear-credito/formulario":
        setLoading(true);

        localStorage.setItem("latestStep", "complement-data");

        const brokerId = localStorage.getItem("broker_id");

        try {
          const body = {
            vig_inicial: null,
            sexo: null,
            prima_total: null,
            placa: null,
            ocupacion_titular_credito: formData.job,
            numero_doc: null,
            nombres: null,
            no_poliza: null,
            no_cuotas: 1,
            linea: null,
            ingresos_titular_del_credito: formData.salary,
            id: formId,
            fecha_nacimiento: null,
            fecha_expedicion: null,
            estado_civil: null,
            departamento_nacimiento: null,
            departamento_expedicion: null,
            correo_electronico: formData.email,
            ciudad_nacimiento: null,
            ciudad_expedicion: null,
            cel_titular_credito: null,
            asesor_intermediario: brokerId,
            apellidos: null,
            abono_inicial: formData.init_credit,
            ciudad_residencia: formData.city,
            direccion_residencia: formData.address,
          };

          localStorage.setItem("data", JSON.stringify(body));

          history(inputData.redirect);
        } catch (err) {
          console.log(err);
        }
        break;
      case "/admin/crear-credito/formulario-renovacion-confirmar":
        const uniqueArrayRenovation = [...new Set(modifiedField)];

        localStorage.setItem(
          "modifiedFields",
          JSON.stringify(uniqueArrayRenovation)
        );

        setLoading(true);

        console.log(formData);

        try {
          const body = {
            vig_inicial: formData.init_term,
            sexo: formData.genre,
            prima_total: formData.total_annual,
            placa: formData.plate,
            ocupacion_titular_credito: formData.job,
            numero_doc: formData.num_document,
            nombres: formData.first_name,
            no_poliza: formData.num_policy,
            no_cuotas: formData.num_shares,
            linea: formData.line,
            ingresos_titular_del_credito: formData.salary,
            id: formId,
            fecha_nacimiento: formData.birthdate,
            fecha_expedicion: null,
            estado_civil: null,
            departamento_nacimiento: null,
            departamento_expedicion: null,
            correo_electronico: formData.email,
            ciudad_nacimiento: formData.birth_place,
            ciudad_expedicion: null,
            cel_titular_credito: formData.phone,
            asesor_intermediario: formData.broker,
            apellidos: formData.last_name,
            abono_inicial: formData.init_credit,
            id_sucursal: formData.insurer_place,
            ciudad_residencia: formData.city,
            direccion_residencia: formData.address,
            es_renovacion: true,
            id_solicitante: formData.agent,
            ing_Intermediario: formData.brokerage_income,
            tipo_documento: formData.doc_type,
          };

          fetch(`${BACKEND_API}${inputData.endpoint}`, {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json",
            },
            body: JSON.stringify(body),
          })
            .then((res) => res.json())
            .then(({ data }) => {
              setShowPopup(true);
              setPopupData({
                title: "Enviado Exitosamente",
                info: "Tu información ha sido recibida exitosamente.",
                type: "correct",
              });

              localStorage.setItem("isCreditInProcess", false);
              localStorage.removeItem("fromRenovationTable");
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (err) {
          console.log(err);
        }
        break;
      case "/admin/crear-credito/formulario-confirmar":
        const uniqueArray = [...new Set(modifiedField)];

        localStorage.setItem("modifiedFields", JSON.stringify(uniqueArray));
        setLoading(true);

        try {
          const body = {
            vig_inicial: formData.init_term,
            sexo: formData.genre,
            prima_total: formData.total_annual,
            placa: formData.plate,
            ocupacion_titular_credito: formData.job,
            numero_doc: formData.num_document,
            nombres: formData.first_name,
            no_poliza: formData.num_policy,
            no_cuotas: formData.num_shares,
            linea: formData.line,
            ingresos_titular_del_credito: formData.salary,
            id: formId,
            fecha_nacimiento: formData.birthdate,
            fecha_expedicion: null,
            estado_civil: null,
            departamento_nacimiento: null,
            departamento_expedicion: null,
            correo_electronico: formData.email,
            ciudad_nacimiento: formData.birth_place,
            ciudad_expedicion: null,
            cel_titular_credito: formData.phone,
            asesor_intermediario: formData.broker,
            apellidos: formData.last_name,
            abono_inicial: formData.init_credit,
            id_sucursal: formData.insurer_place,
            ciudad_residencia: formData.city,
            direccion_residencia: formData.address,
            es_renovacion: false,
            id_solicitante: formData.agent,
            ing_Intermediario: formData.brokerage_income,
            tipo_documento: formData.doc_type,
          };

          fetch(`${BACKEND_API}${inputData.endpoint}`, {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json",
            },
            body: JSON.stringify(body),
          })
            .then((res) => res.json())
            .then(({ data }) => {
              if (data.Estado === "Exitoso") {
                setShowPopup(true);
                setPopupData({
                  title: "Enviado Exitosamente",
                  info: "Tu información ha sido recibida exitosamente.",
                  type: "correct",
                });

                localStorage.setItem("isCreditInProcess", false);
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (err) {
          console.log(err);
        }
        break;
      default:
        break;
    }
  };
  const handleNext = (e) => {
    e.preventDefault();

    const form = e.target.closest("form"); // Obtén el formulario más cercano

    if (inputData.type === "Nuevo") {
      if (stepNumber === 0) {
        // Validaciones adicionales para el tipo "Nuevo"
        if (
          !formData.phone ||
          formData.phone[0] !== "3" ||
          formData.phone[1] === "3" ||
          formData.phone.length !== 10
        ) {
          document
            .getElementById("phone")
            .setCustomValidity("Número de teléfono inválido");
        } else {
          document.getElementById("phone").setCustomValidity("");
        }
      }
    }

    // Si el formulario es válido, avanza al siguiente paso
    if (form.checkValidity()) {
      setStepNumber(stepNumber + 1);
      setProgress(progress + 1);
    } else {
      form.reportValidity();
    }
  };

  const handleDependent = (e, childrenName) => {
    const selectCity = document.getElementById(childrenName);

    if (e.target.id === "department" && childrenName === "city") {
      try {
        selectCity.innerHTML = "";

        const body = {
          id_department: e.target.value,
          get_cities: true,
          get_departments: false,
        };
        fetch("https://crediseguro-back.click/getLocation", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.Ciudad) {
              const noneOption = document.createElement("option");
              noneOption.text = "--Ninguno--";
              noneOption.value = "";

              selectCity.appendChild(noneOption);

              data.Ciudad.map((city, i) => {
                const option = document.createElement("option");
                option.text = city.Nombre;
                option.value = city.Id;
                selectCity.appendChild(option);

                const optionsArray = Array.from(selectCity.options);

                optionsArray.sort((a, b) => a.text.localeCompare(b.text));

                selectCity.innerHTML = "";

                return optionsArray.forEach((option) => {
                  selectCity.appendChild(option);
                });
              });
            }
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleGetFormData = () => {
    getRefreshDataState(!refreshData);
  };

  return (
    <div className="form-with-steps-container">
      <div className="header-title-container">
        <h1>Solicitud de {inputData.headerTitle} Persona Natural</h1>
      </div>
      <div className="steps-graph-container">
        {inputData.steps[stepNumber].subtitleStep}
        {hasSteps && (
          <div className="step-bar-container">
            <div
              className="progress-bar"
              style={{ width: `${progressFraction * progress}%` }}
            ></div>
          </div>
        )}
      </div>
      {hasRestoreBtn && (
        <div className="reset-btn-container">
          <button
            title="Reiniciar Formulario"
            className="reset-btn"
            onClick={handleGetFormData}
          >
            Reiniciar Formulario
          </button>
        </div>
      )}
      <form
        className="fws-form-container"
        onChange={handleForm}
        onSubmit={handleSubmit}
      >
        <div className="current-step">
          {inputData.steps[stepNumber].inputs.map((input) => {
            if (input.isSelect) {
              if (input.viewPermissions?.includes(userProfile)) {
                return (
                  <label key={input.key}>
                    {input.title}
                    <select
                      value={formData[input.name]}
                      name={input.name}
                      title={input.typeVal}
                      id={input.name}
                      required
                      disabled={input.isBlocked}
                      autoComplete="off"
                    >
                      {!input.isBlocked && (
                        <option value="">--Ninguno--</option>
                      )}
                      {input.options.map((option, i) => {
                        return (
                          <option
                            key={i}
                            value={option.value ? option.value : option.title}
                            selected={option.isSelected}
                          >
                            {option.title}
                          </option>
                        );
                      })}
                    </select>
                    <span className="mandatory-text hide">
                      Campo Obligatorio
                    </span>
                  </label>
                );
              }
            }
            if (input.viewPermissions?.includes(userProfile)) {
              if (input.isDependent) {
                return (
                  <label key={input.key}>
                    {input.title}
                    <select
                      value={formData[input.name]}
                      name={input.name}
                      id={input.name}
                      title={input.typeVal}
                      onChange={(e) => handleDependent(e, input.childrenName)}
                      required
                      autoComplete="off"
                    >
                      <option value="">--Ninguno--</option>
                      {input.options.map((option, i) => {
                        return (
                          <option
                            key={i}
                            id={option.id ? option.id : ""}
                            value={option.title}
                          >
                            {option.title}
                          </option>
                        );
                      })}
                    </select>
                    <span className="mandatory-text hide">
                      Campo Obligatorio
                    </span>
                  </label>
                );
              }
            }
            if (input.viewPermissions?.includes(userProfile)) {
              return (
                <label key={input.key}>
                  {input.title}
                  <input
                    title={input.typeVal}
                    type={input.type}
                    name={input.name}
                    id={input.name}
                    value={formData[input.name]}
                    autoComplete="off"
                    required
                    disabled={input.isBlocked}
                  />
                  <span className="mandatory-text hide">Campo Obligatorio</span>
                </label>
              );
            }
          })}
        </div>
        <div className="fws-btns-container">
          {inputData.steps[stepNumber].hasPrev && (
            <button
              onClick={(e) => {
                e.preventDefault();
                setStepNumber(stepNumber - 1);
                setProgress(progress - 1);
              }}
              className="fws-btn"
              title="Anterior"
            >
              Anterior
            </button>
          )}
          {inputData.steps[stepNumber].hasNext && (
            <button onClick={handleNext} className="fws-btn" title="Siguiente">
              Siguiente
            </button>
          )}
          {inputData.steps[stepNumber].hasSend && (
            <button type="submit" className="fws-btn" title="Enviar">
              Enviar
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default FormWithStepsComponent;
